// src/config/promotion.js

const promotions = [
    {
        id: 1,
        message: '🎄 Christmas Sale! Get 80% off on all items!',
        startDate: new Date('2024-12-20'),
        endDate: new Date('2024-12-27'),
        backgroundColor: '#ff0000',
    },
    {
        id: 2,
        message: '🔥 Black Friday Deal! Up to 90% off!',
        startDate: new Date('2024-11-24'),
        endDate: new Date('2024-11-30'),
        backgroundColor: '#000000',
    },
    {
        id: 3,
        message: '💖 Valentine\'s Day Special! 70% off for your loved ones!',
        startDate: new Date('2025-02-10'),
        endDate: new Date('2025-02-15'),
        backgroundColor: '#ff69b4',
    },
];

export const getCurrentPromotion = () => {
    const now = new Date();
    return promotions.find(
        (promotion) => now >= promotion.startDate && now <= promotion.endDate
    );
};

export default promotions;
