// src/components/HeroSection.jsx
import React, { useState, useEffect } from 'react';
import heroImage from '../assets/hero.png';
import heroText from '../assets/hero_text.png';
import { getCountdownEndTime } from '../services/countdownService';
import { createCheckoutSession } from '../services/checkoutService';

const HeroSection = () => {
    const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
    const [loading, setLoading] = useState(true);
    const [countdownEndTime, setCountdownEndTime] = useState(null);

    // Function to calculate time left
    const calculateTimeLeft = (endTime) => {
        const now = new Date();
        const difference = endTime - now;

        if (difference > 0) {
            return {
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / (1000 * 60)) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        } else {
            return { hours: 0, minutes: 0, seconds: 0 };
        }
    };

    // Fetch updated countdown time
    const fetchCountdownEndTime = async () => {
        try {
            const { endTime } = await getCountdownEndTime();
            const newEndTime = new Date(endTime);
            setCountdownEndTime(newEndTime);
        } catch (error) {
            console.error('Error fetching countdown timer:', error);
        }
    };

    // Buy Now handler
    const handleBuyNow = async () => {
        try {
            const checkoutUrl = await createCheckoutSession();
            window.location.href = checkoutUrl;
        } catch (error) {
            alert('Failed to initiate checkout. Please try again.');
        }
    };

    useEffect(() => {
        // Initial fetch of countdown end time
        const initializeCountdown = async () => {
            setLoading(true);
            await fetchCountdownEndTime();
            setLoading(false);
        };

        initializeCountdown();

        // Periodically fetch updated end time every 1 minute
        const interval = setInterval(fetchCountdownEndTime, 60000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        // Update timer every second based on countdownEndTime
        let timer;
        if (countdownEndTime) {
            timer = setInterval(() => {
                const remainingTime = calculateTimeLeft(countdownEndTime);
                setTimeLeft(remainingTime);

                // If countdown reaches zero, fetch the updated end time
                if (
                    remainingTime.hours === 0 &&
                    remainingTime.minutes === 0 &&
                    remainingTime.seconds === 0
                ) {
                    fetchCountdownEndTime();
                }
            }, 1000);
        }

        return () => clearInterval(timer);
    }, [countdownEndTime]);

    const formatTime = (time) => (time < 10 ? `0${time}` : time);

    return (
        <section className="relative flex items-center justify-center h-screen bg-gradient-to-br from-gray-900 to-black text-white pt-20 sm:pt-24 md:pt-0">
            <div className="container mx-auto flex flex-col md:flex-row items-center justify-between px-4 text-center md:text-left">
                <div className="flex flex-col items-center md:items-start md:w-1/2 space-y-4 md:space-y-6 lg:space-y-8">
                    <img src={heroText} alt="2,000,000+ The Ultimate Graphic & Video Editing Bundle" className="w-full max-w-xs sm:max-w-md mb-2 sm:mb-4 lg:ml-8 ml-0" />
                    <p className="text-sm sm:text-lg lg:-ml-8 ml-0">
                        Get lifetime access to our exclusive bundle for just <span className="line-through text-gray-400">$279</span> <span className="text-green-500">$47</span>. Limited time offer!
                    </p>
                    <div className="text-xl flex justify-center items-center w-full ml-0 sm:ml-10 lg:-ml-32">
                        <span className="line-through text-red-500">$279</span>
                        <span className="text-green-500 text-5xl font-bold ml-2">$47</span>
                    </div>
                    <button onClick={handleBuyNow} className="w-full max-w-md px-8 py-3 bg-white text-black rounded-lg text-xl sm:text-xl font-bold hover:bg-gray-300 mt-4 lg:ml-6 ml-0">
                        Buy Now
                    </button>
                    <div className="mt-6 text-center lg:ml-40 ml-0">
                        <p className="text-red-500 uppercase mb-2">Limited Time Offer</p>
                        {loading ? (
                            <div className="text-2xl font-bold text-red-500">Loading...</div>
                        ) : (
                            <div className="text-2xl font-bold text-red-500">
                                {formatTime(timeLeft.hours || 0)} : {formatTime(timeLeft.minutes || 0)} : {formatTime(timeLeft.seconds || 0)}
                            </div>
                        )}
                    </div>
                </div>
                <div className="md:w-1/2 mt-6 md:mt-0 text-center md:text-right">
                    <img src={heroImage} alt="Ultimate Bundle" className="w-full max-w-xs sm:max-w-sm md:max-w-lg mx-auto" />
                </div>
            </div>
        </section>
    );
};

export default HeroSection;