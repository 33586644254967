// src/components/TopHeader.jsx
import React, { useEffect, useState } from 'react';
import { getCurrentPromotion } from '../config/promotion';

const TopHeader = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [promotion, setPromotion] = useState(null);

    useEffect(() => {
        const currentPromotion = getCurrentPromotion();
        if (currentPromotion) {
            setPromotion(currentPromotion);
        } else {
            setIsVisible(false); // Hide if no promotion is active
        }
    }, []);

    if (!isVisible || !promotion) return null;

    return (
        <div
            className="top-header flex items-center justify-center px-4 py-4 text-white text-sm md:text-base"
            style={{ backgroundColor: promotion.backgroundColor }}
        >
            <p className="top-header-message text-center">{promotion.message}</p>
        </div>
    );
};

export default TopHeader;
