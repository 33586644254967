// /src/services/countdownService.js
const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const getCountdownEndTime = async () => {
    try {
        const response = await fetch(`${apiBaseUrl}/countdown/countdown-end-time`, {
            method: 'GET',
        });

        if (!response.ok) {
            throw new Error('Failed to fetch countdown end time');
        }

        return await response.json(); // Return the JSON response with the end time
    } catch (error) {
        console.error('Error fetching countdown end time:', error);
        throw error;
    }
};

export const setCountdownEndTime = async (endTime) => {
    try {
        const response = await fetch(`${apiBaseUrl}/countdown/countdown-end-time`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ endTime }),
        });

        if (!response.ok) {
            throw new Error('Failed to set countdown end time');
        }

        return await response.json();
    } catch (error) {
        console.error('Error setting countdown end time:', error);
        throw error;
    }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { getCountdownEndTime, setCountdownEndTime };